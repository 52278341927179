// Bootstrap
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Write your SCSS here

@import url(//fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800);

body {
    font-family:"PingFang TC", "Heiti TC", "Noto Sans CJK TC", "Microsoft JhengHei Fixed", "Microsoft JhengHei", "微軟正黑體";
    color: #000;
    font-size: 18px;
    line-height: 2;
    background-color: #F5F5F5;
}

@media screen and (min-width: 768px){
  body {
    &.admin-menu.adminimal-menu {
      .sticky-header{
        top: 30px;
      }
    }
  }
}

img{
  max-width: 100%;
  height: auto;
}

.field-body,
.node{
  p, .media-p {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    text-align: justify;
    letter-spacing: 1px;
  }
}

.clear-right{
  clear:right;
}
.clear-left{
  clear:left;
}
.clear-both{
  clear:both;
}

blockquote {
  background: url(../image/quote.png) no-repeat scroll 5px 10px #EEE;
  background-size: 20px auto;
  border: 1px solid #DFDADA;
  border-radius: 10px;
  font-size: 17.5px;
  margin: 1.5em 0;
  padding: 1.5em;
  p{
    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.region-content{
  a{
    color: #075666;
    transition: color 0.5s, border-color 0.5s;
    &:hover{
      color: #00A075;
      text-decoration: none;
    }
    &[class*="btn-"]{
      color: #fff;
      font-weight: bold;
    }
  }
  .teaser, .views-field-field-image{
    a{
      color: #000;
      border-bottom: none;
      &:hover{
        color: #000;
        border-bottom: none;
      }
    }
    .title a:hover{
      color: #fff;
    }
  }
  .view-popular-content{
    .image a {
      color: #FFFFFF !important;
    }
  }
}

.nav-tabs{
  flex-wrap: wrap;
}

/* ANCHOR Menu */

#superfish-2{
  display: flex;
}

#superfish-3{
  background: none;
  display: flex;
  align-items: center;
  li{
    background: rgba(255,255,255, 0.9);
    &:hover{
      background: rgba(255,255,255, 1);
    }
    .user{
      background: url(https://philomedium.com/sites/default/files/styles/head_small/public/default_images/head_s.png) no-repeat center center;
      background-size: 35px 35px;
      min-width: 35px;
      min-height: 35px;
      display: block;
      img{
        width: 35px;
        max-width: 35px;
        border-radius: 50%;
        position: relative;
        height: 35px;
        left: calc((100% - 35px)/2);
      }
    }
    .link-badge {
      padding: 2px 4px 2px 4px;
    }
  }
}

.sticky-header-enabled{
  .region-content{
    margin-top: 50px;
  }
}

@media screen and (max-device-width: 480px){
  .sticky-header-enabled{
    .sticky-header-wrapper{
      height: 70px !important;
    }
  }
}

/* ANCHOR Form */

input{
  max-width: 100%;
  &[readonly]{
    background-color: rgb(196, 196, 196);
  }
}

/* NOTE hide current pass field from user */
#edit-account{
  .form-item-current-pass{
    display: none;
  }
}

/* ANCHOR table */

table{
  width: 100%;
  border-radius:10px;
  height: 60px;
  thead{
    tr{
      background-color: #36304a;
      color: white;
    }
  }
  tbody{
    tr{
      height: 50px;
      &.even{
        background-color: #e3e3e3;
      }
    }
  }
}

/* ANCHOR Layout */

.sticky-header-wrapper {
  margin-bottom: 1rem;
}

header{
  .logo--wrapper{
    height: 40px;
    width: 144px;
  }
}

.region-footer { /* ANCHOR Footer */
  padding: 5rem 0 0;
  .panel-pane{
    .pane-title{
      text-align: left;
      font-size: 23px;
      .title-text::after{
        content: '';
        display: block;
        width: 130px;
        border-bottom: 2px solid #009183;
        padding-top: 6px;
      }
    }
  }
  .pane-menu-menu-social-media{
    .menu{
      list-style: none;
      display: flex;
      justify-content: space-between;
      padding:0;
      a{
        font-size: 26px;
        display: flex;
        color: #000;
        border: 2px solid #000;
        border-radius: 23px;
        width: 46px;
        height: 46px;
        justify-content: center;
        align-items: center;
        &:hover{
          text-decoration: none;
          background-color: #000;
          color: #fff;
          border-color: #fff;
        }
      }
    }
  }
  .pane-page-logo{
    margin-top: 38px;
  }
  #block-copyright-block-copyright-block{
    background-color: #000;
    color: #fff;
    padding: 15px;
    text-align: center;
  }
}

/* ANCHOR slideshow */

.view-id-frontpage_slideshow{
  .slides{
    .field-content{
      div{
        width: 100vw;
        height: 80vw;
      }
    }
  }
}

@media screen and (min-width: 768px){
  .view-id-frontpage_slideshow{
    .slides{
      .field-content{
        div{
          height: 39vw;
        }
      }
    }
  }
}

.flexslider{
  border: 0px solid #fff;
  .flex-control-nav{
    bottom: -20px;
    z-index: 2;
  }
  .flex-control-paging li a{
    background: rgba(0, 0, 0, 0.4);
    &.flex-active{
      background: rgba(255, 255, 255, 0.4);
    }
  }
}

.flex-direction-nav{
  a{
    &::before{
      font-size: 28px;
    }
  }
}

.slick-prev::before, 
.slick-next::before {
  font-family: 'FontAwesome';
  color: gray;
}

/* ANCHOR Panel */

.panel-pane{
  h2.pane-title{
    font-size: 40px;
    .title-text{
      background-color: transparent;
    }
    &::before, &::after{
      content:unset;
    }
  }
}

/* ANCHOR Panel Title 類型單元頁 */

.page-content{
  .pane-page-title{
    .pane-title{
      height: 75px;
      line-height: 75px;
      margin: 45px auto;
      background: transparent url(../image/2020/section_report.svg) no-repeat center center;
      &.report{
        background-image: url(../image/2020/section_report.svg);
      }
      &.blog{
        background-image: url(../image/2020/section_blog.svg);
      }
    }
  }
}


/* ANCHOR Frontpage 首頁 */

.page-home{
  #latest{
    .pane-title{
      height: 100px;
      line-height: 100px;
      background: transparent url(../image/2020/section_latest.svg) no-repeat center center;
      background-size: contain;
      font-weight: 900;
    }
  }
  
  #philosophers{
    padding-top: 200px;
    background: transparent url(../image/2020/section_philosopher.svg) no-repeat center 150px;
    background-size: contain;
    .pane-title{
      margin-top: 10px;
    }
    .pane-content{
      width: 272px;
      margin-left: 38px;
      .views-field-name{
        margin-top: 24px;
      }
    }
    .slick--skin--boxed > .slick__slider > .slick-list {
      margin: 0px 45px 0 49px;
      width: 167px;
    }
  }

  #channel{
    .pane-title{
      height: 81px;
      line-height: 81px;
      margin: 80px auto;
      .title-text{
        &::after{
          content: '';
          display: block;
          width: 176px;
          height: 80px;
          background: transparent url(../image/2020/section_channel.svg) no-repeat center center;
          position: absolute;
          top: 0;
          left: 47px;
        }
      }
    }
  }
  #channel-2{
    .content-wrapper{
      background-color: #fff;
    }
  }
  #blog, #blog-2{
    background-color: #fff;
    width: 100vw;
    margin-left: calc((100% - 100vw) /2);
    padding: 3rem calc((100vw - 100%) / 2);
    margin-bottom: 0;
    .pane-title{
      height: 70px;
      line-height: 70px;
      background: transparent url(../image/2020/section_blog.svg) no-repeat center center;
      margin: 0 auto 70px;
    }
  }
  #blog-2{
    padding-top: unset;
    padding-bottom: unset;
  }

  .row{
    &:nth-child(2){
      background-color: #fff;
      width: 100vw;
      margin-left: calc((100% - 100vw) /2);
      padding: 0 calc((100vw - 100%) / 2);
    }
  }

  #report{
    .pane-title{
      height: 70px;
      line-height: 70px;
      background: transparent url(../image/2020/section_report.svg) no-repeat center center;
      margin: 70px auto;
    }
  }
  #report-2{
    border-bottom: 1px dashed #cfd8dc;
  }
  
  #biblio{
    .pane-title{
      height: 97px;
      line-height: 97px;
      background: transparent url(../image/2020/section_biblio.svg) no-repeat 40% center;
      margin: 90px auto 24px;
    }
    .pane-content{
      background-color: #fff;
      padding: 40px 20px;
    }

  }
  #quicktabs-latest_biblio{
    .quicktabs-tabpage{
      background-color: #fff;
      padding: 30px 20px;
    }
  }
}

#quotation{
  .pane-title{
    height: 97px;
    line-height: 97px;
    background: transparent url(../image/2020/section_quotation.svg) no-repeat 40% center;
    margin: 90px auto 24px;
  }
  .pane-content{
    background-color: #fff;
  }
}

.pane-link{
  .pane-title{
    margin-top: 60px;
  }
}

@media screen and (max-device-width: 480px){
  .page-home{
    #philosophers{
      padding-top: 36px;
      background-position: 0px 0px;
    }
  }
}

/* ANCHOR Term Menu */

.term-menu{
  ul{
    text-align: center;
    li{
      display: inline-block;
      margin-right: 20px;
        a{
          display: flex;
          color: #999898;
          font-size: 1.75rem;
          padding:5px 10px;
          border-bottom: 2px solid transparent;
          &:hover, &.active{
            color: #474747;
            border-bottom: 2px solid #F9BA4F;
            text-decoration: none;

          }
          .field-image-icon{
            margin-right: 5px;
            img{
              width: 20px;
            }
          }
        }
    }
  }
}

.content--sidebar-right{
  .term-menu a{
    font-size: 1.2rem;
  }
}

/* ANCHOR Term page */

.term-banner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .term-name{
    font-size: 2.5rem;
    letter-spacing: 5px;
    font-weight: 900;
    padding-left: 100px;
  }
  .term-description{
    max-width: 60%;
    p{text-align: center;}
  }
}


/* ANCHOR Term field */

.field-item.category{
  .category-name{
    display: inline-block;
  }
}

.category{
  &:last-child{
    position: relative;
    &::after{
      content: ""; 
      position: absolute; 
      width: 80px; 
      top: 50%; 
      margin-left: 10px;
      border-top: 2px solid #474747; 
    }
    &.report::after{
      border-color: #FABD47;
    }
    &.blog::after{
      border-color: #77ADCD;
    }
    .category-name{
      a{
        color: #939393;
      }
    }
  }
  .category-image-icon{
    width: 16px;
  }
} 

.pane-entity-field{
  .field-cate-blog{
    .category{
      .category-name{
        &::after{
          border-top: 2px solid #78AECD; 
        }
      }
    }
  } 
}

/* ANCHOR Five stars */

.field-difficulty.inline{
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

/* ANCHOR Head */

.head{
  img{
    border-radius: 50%;
    border: 2px solid #fff;
  }
}

.event.lecturer .head{
  min-width: 100px;
}

/* ANCHOR Profile */

.user-profile-wrapper{
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 125px;
    width: 100%;
    top: 0px;
    background-color: #77ADCD;
    left: 0;
  }
  .field-image{
    img{
      @include img-fluid;
      background: #FFF;
      padding: 2px;
      border: 1px solid #ccc;
      border-radius: 50%;
    }
  }
  .job-title{
    display: flex;
    color: rgb(228, 228, 228);
    .field-status{
      margin-right: 20px;
    }
  }
  .user-name{
    color: #fff;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
  }
  .social{
    .item-list--linkicon{
      margin: 5px 0;
    }
  }
  .expert{
    .field-cate-idea{
      display: inline;
      &::after{
        content: '、';
      }
      &:last-child::after{
        content: '';
      }
    }
  }
  .introduction{
    color: #2a2a2a;
    height: 126px;
    overflow: hidden;
    transition: background-image 0.5s ease-out;
    position: relative;
    &::after{
      content: 'more';
      display: block;
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      padding-top: 30px;
      text-align: center;
    }
    &:hover{
      min-height: 140px;
      height: unset;
      cursor: pointer;
      &::after{
        content: '';
        background-image: none;
      }
    }
  }
}

.user{
  .user-profile-wrapper{
    &::before {
      background-color: #F4C035;
    }
  }
}

@media  screen and (max-device-width: 576px){
  .user-profile-wrapper{
    &::before {
      height: 255px;
    }
  }
}

/* ANCHOR Subscriber Center */

.member-center{
  .bulletin{
    .pane-content{
      background: #FABD47 url(../image/2020/bulletin_3.svg) no-repeat center center;
      background-size: cover;
      padding: 20px;
      .frame{
        border: 5px solid #fff;
        font-weight: bold;
        padding-left: 160px;
        padding-right: 325px;
        position: relative;
        background: url(../image/2020/bulletin_2.svg) no-repeat center right;
        background-size: contain;
        &::before{
          content: '';
          display: block;
          width: 57px;
          height: 187px;
          background: url(../image/2020/bulletin_1.svg) no-repeat center center;
          position: absolute;
          top: 20px;
          left: 50px;
        }
      }
    }
  }
  #subscriber{
    .pane-title{
      background: url(../image/2020/section_subscriber.svg) no-repeat center center;
      margin-bottom: 40px;
    }
    .card{
      box-shadow: 3px 3px 10px 0 gray;
      &:hover{
        box-shadow: 0px 0px 5px 0 gray;
      }
      .card-img{
        width: 100%;
        min-height: 85px;
        background: transparent url(../image/subscriber/bt-course.svg) no-repeat center center ;
        background-size: contain;
        margin: 20px 0 15px;
      }
      &#user{
        .card-img{
          background-image: url(../image/subscriber/bt-user.svg);
        }
      }
      &#opinion{
        .card-img{
          background-image: url(../image/subscriber/bt-opinion.svg);
        }
      }
      &#faq{
        .card-img{
          background-image: url(../image/subscriber/bt-faq.svg);
        }
      }
    }
  } 
}

@media (max-width: 768px) {
  .member-center{
    .bulletin{
      .pane-content{
        background: #FABD47 url(../image/2020/bulletin_3.svg) no-repeat center right;
        background-size: cover;
        padding: 20px;
        .frame{
          padding-left: 60px;
          padding-right: 170px;
          background: url(../image/2020/bulletin_2.svg) no-repeat center right;
          background-size: 160px auto;
          &::before{
            position: absolute;
            top: 10px;
            left: -20px;
          }
        }
      }
    }
  }
}

@media (max-width: 544px) {
  .member-center{
    .bulletin{
      .pane-content{
        background: #FABD47 url(../image/2020/bulletin_3.svg) no-repeat bottom right;
          background-size: cover;
        .frame{
          padding-right: 20px;
          background-image: none;
        }
      }
    }
  }
}

.pane-quicktabs-subscriber-center{
  .pane-title{
    background: url(../image/2020/section_subscriber.svg) no-repeat center center;
    margin-bottom: 40px;
  }
  .pane-content{
    .pane-title{
      background-image: none;
    }
  }
  ul.quicktabs-tabs{
    &.quicktabs-style-nostyle{
      li{
        a{
          color: #415462;
          letter-spacing: 2.4px;
          font-size: 1.2rem;
        }
        &.active a, a:hover{
          background-color: #415462;
          color: #fff;
        }
      }
    }
  }
}

/* ANCHOR FAQ */

.page-faq{
  .page-title{
    background: url(../image/2020/section_faq.svg) no-repeat center center;
    background-size: cover;
    height: 126px;
    margin-bottom: 40px;
    color: transparent;
  }
  .views-accordion-faq-page-header{
    margin: 30px 0;
  }
}

.ui-accordion{
  .views-row{
    margin-bottom: 15px;
    .ui-state-default{
      background-color: #fff;
      background-image: unset;
      .field-content{
        a{
          font-weight: bold;
        }
      }
    }
  }
}

/* ANCHOR 會員意見反映 */

.page-node-81053,
.page-node-81398{
  .pane-page-title{
    h2{
      background: transparent url(../image/subscriber/bt-opinion.svg) no-repeat 0 center ;
      background-size: contain;
      padding-left: 120px;
    }
  }
}

/* ANCHOR 兌換線上課程 */

.page-node-81398{
  .pane-page-title{
    h2{
      background-image: url(../image/subscriber/bt-course.svg);
    }
  }
}

/* ANCHOR Quicktab */

.quicktabs_main.quicktabs-style-sky {
  padding: 10px 20px;
}

/* ANCHOR node */

.Unpublished .container .field-body{
  &::before{
    content: '本文尚未發表';
    display: block;
    font-size: 4rem;
    letter-spacing: 2px;
    text-shadow: 1px 1px 5px black;
    color: white;
    text-align: center;
    font-weight: bolder;
    background-image: linear-gradient(to bottom, rgba(255, 215, 215, 1), rgba(255, 215, 215, 0.5));
  }
}

.field-body{
  h1,h2,h3,h4,h5,h6{
    letter-spacing: 2px;
    font-weight: bold;
  }
}

.node-type-report{
  .field-body{
    h1,h2,h3,h4,h5,h6{
      color: #ca9400;
    }
  }
}

.node-type-blog{
  .field-body{
    h1,h2,h3,h4,h5,h6{
      color: #80aed0;
    }
  }
}

.page-node{ 
  .author{
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    &.blog{background-color: rgba(128,174,208, 0.1);}
  }
  .pane-node-links ul.links.inline {
    display: inline-flex;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    .blog_usernames_blog{
      display: none;
    }
  }
}

cite{
  font-style: normal;
  font-weight: 900;
  display: block;
  padding-left: 20px;
  margin-top: 4em;
  border-left: 5px solid #cd0303;
}

/* ANCHOR comment */

.comment-wrapper{
  label{
    display: inline;
    font-weight: normal !important;
    font-size: .8rem;
  }
}

.comment{
  img{
    height: max-content;
  }
}

/* ANCHOR Addtoany */

.node-type-report .a2a_kit .a2a_svg{background-color: #ca9400 !important;}
.node-type-blog .a2a_kit .a2a_svg{background-color: #77ADCD !important;}

@media (min-width: 1300px){
  .pane-reporter-social-buttons,
  .pane-content-author-social-buttons{
    .addtoany_list{
      position: fixed;
      left: calc(((100% - 1232px) / 2) - 90px);
      bottom: 10vh;
      width: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      > a{
        margin-bottom: 10px;
        .a2a_svg{
          background-color: transparent !important;
          svg{
            path{fill: #000 !important;}
          }
        }
      }
    }
  }
}


/* ANCHOR Quotation */

.node-type-quotation{ 
  .views-field-nothing{
    .row{
      margin: 0;
      align-items: flex-start;
    }
  }  
  p.quotation{
  padding: 20px;
  border: 6px solid #46686F;
  border-radius: 40px;
  width: 100%;
  background-color: #eee;
  color: #1A1A1A;
  position: relative;
  box-shadow: 2px 2px 4px #888;
  }
  
  p.quotation{
    &::before,
    &::after{
      display: block;
      width: 0px;
      content: "";
      position: absolute;
      border-style: solid;
      bottom: auto;
    }
    &::before{
      bottom: -50px;
      left: 50px;
      border-width: 25px;
      border-color: #46686F transparent transparent transparent;
    }
    &::after{
      bottom: -41px;
      left: 51px;
      border-width: 24px;
      border-color: #eee transparent transparent transparent;
    }
    .quote{
      font-size: 1.2rem;
      font-weight: bold;
      color: #000;
    }
  }
  div.philosopher{
  width: 100%;
  margin: 20px 0 0;
  text-align:center;
    img{
      border-radius: 50%;
    }
  }
  .reference{
    display:block;
    margin: 10px 0px 0;
    font-size: 0.8em;
    font-style: italic;
    .biblio,
    .link{
      display:block;
      margin: 5px 0px;
    }
  } 
}

#quotation .teaser .content-wrapper{
  padding-top: 1.25rem !important;
}

@media screen and (min-width: 768px){
  .node-type-quotation{
    .views-field-nothing{
      .row{
        flex-wrap: nowrap;
        flex-direction: row-reverse;
      }
    }
    div.philosopher{
      margin: 20px 0 0;
      text-align:center;
      flex: 1;
    }
    p.quotation{
      flex: 2;
      &::before{
        top: 50px;
        left: -51px;
        bottom: unset;
        border-width: 25px;
        border-color: transparent #46686F #46686F transparent;
      }
      &::after{
        top: 48px;
        left: -39px;
        bottom: unset;
        border-width: 24px;
        border-color: transparent #eee #eee transparent;
      }
    }
  }
}

/* ANCHOR teaser display */

.teaser{
  /* &:hover{
    box-shadow: 1px 1px 5px rgba(0,0,0, 0.6);
  } */
  .content-wrapper,
  &.teaser--default .content-wrapper {
    padding-top: 0.5rem !important;
    margin-top: 0;
  }
  .title{
    a:hover{
      div{
        background-color: rgba(0, 0, 0, 1);
        display: inline;
      }
    }
  }
  .category{
    padding-left: 0;
    left: 0;
    .category-icon{
      position: unset;
      display: inline-block;
    }
  }
  .subtitle, .serial{
    font-size: 0.9rem;
  }
  &.teaser--default{
    .subtitle{
      font-size: unset;
      color: rgba(38, 50, 56, 0.8);;
    }
  }
  
  &.node-video .image,
  &.node-audio .image{
    &::before{
      content: '';
      background: transparent url(../image/2020/play.svg) no-repeat center center;
      opacity: 0.8;
      background-size: 20%;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
    }
    &:hover::before{
      opacity: 1;
    }
  }
}

.mg-teaser--compact {
  .views-row {
    .teaser--b,
    .teaser--c{
      &.node-biblio{
        .category{
          font-size: 0.9rem;
          &.biblio::after{
            width: 0;
          }
        }
        .title{
          height: 46px;
          font-size: 1.1rem;
          overflow: hidden;
        }
        .field-subtitle-2,
        .field-body{
          display: none;
        }
      }
    }
  }
}

.teaser-image-parallax{
  .title {
    font-size: 3.5rem;
  }
  .subtitle{
    font-size: 1.8rem;
    color: rgba(255,255,255, 1);
  }
  .category-name, .field-body, .submitted {
    color: rgba(255, 255, 255, 0.95);
    text-shadow: 0 0 2px #000;
  }
}

.mg-teaser--list.flex-row-reverse{
  .teaser{
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
    .image{
      flex: 0 0 70%;
    }
    .content-wrapper{
      padding-right: 1.5rem;
      padding-top: 1.5rem !important;
    }
  }
}

.mg-teaser--4-col-grid{
  .field-video{
    &::before{
      background-size: 20%;
    }
  }
}

.teaser--default{ /* teaser--f */
  a{
    color: #000;
    &:hover{
      text-decoration: none;
    }
  }
}

.teaser--a{ /* teaser--f */
  .content-wrapper{
    text-align: left;
    padding: 0 1rem 1rem;
  }
}

@media screen and (max-device-width: 480px){
  .teaser{
    &.teaser-image-parallax,
    &.teaser-featured{
      .field-body{
        display: none;
      }
    }
    &.teaser-image-parallax{
      .title {
        font-size: 2.5rem;
      }
    }
    &.teaser-featured{
      .title {
        font-size: 1.5rem;
      }
    }
  }
  .mg-teaser--compact {
    .teaser{
      .field-subtitle-2,
      .field-body{
        display: none;
      }
    }
  }
}

// -----------------------------------
// Teaser Style (D)
// -----------------------------------
.teaser--d {
  display: flex;
  align-items: flex-start;
  .content-wrapper {
    padding-left: 1rem;
    padding-top: 5px;
  }
  .image {
    flex: 0 0 120px;
    @include media-breakpoint-up(sm) {
      flex: 0 0 170px;
    }
    @include media-breakpoint-only(lg) {
      flex: 0 0 138px;
    }
  }
}

// -----------------------------------
// Teaser Style (E)
// -----------------------------------
.teaser--e {
  @extend .teaser--d;
  .field-mg-image:before {
    display: none;
  }
  .title {
    font-size: 1.1rem;
    margin-bottom: 0;
  }
}


/* ANCHOR Button */

.pager-load-more{
  a{
    background-color: #80aed0 !important;
    color: #fff !important;
    &:hover{
      background-color:#fff !important;
      color: #80aed0 !important;
    }
  }
}

/* ANCHOR media  */

.media{
  display: unset;
}

.media-wysiwyg-align-center,
.cke_editable .media-wysiwyg-align-center {
    text-align: center;
    clear: both;
}

div.embeddedContent[data-align="right"],
.media-wysiwyg-align-right,
.cke_editable .media-wysiwyg-align-right{
    float: right;
    clear: right;
    margin-left: 1.6em;
}

div.embeddedContent[data-align="left"],
.media-wysiwyg-align-left,
.cke_editable .media-wysiwyg-align-left{
    float: left;
    clear: left;
    margin-right: 1.6em;
}

.media-wysiwyg-align-left,
.media-wysiwyg-align-right,
.cke_editable .media-wysiwyg-align-right,
.cke_editable .media-wysiwyg-align-left{
  margin-top: .8em;
}

div.embeddedContent[data-align="right"],
div.embeddedContent[data-align="left"]{
  margin-top: 1.6em;
}

.media-wysiwyg-align-right,
.media-wysiwyg-align-left {
    max-width: 40%;
}

.cke_editable .media-element.file-caption-right,
.cke_editable .media-element.file-caption-left{
width: 20%;
height: auto;
}

.media-element-container{
  &.media-caption_right,
  &.media-caption_left{
  width: 40%;
  height: auto;
  }
  .media-full{
  max-width: 640px;
  }
  .file.file-video{
    margin: 0 auto 5px;
  }
}


.media-wysiwyg-align-center .content{
border: 0px solid #ccc;
background-color: transparent;
box-shadow: none;
}

.media-caption_left .file-image .content,
.media-caption_right .file-image .content,
.media-wysiwyg-align-left .file-image .content,
.media-wysiwyg-align-right .file-image .content{
border: 1px solid #ccc;
border-radius: 4px;
}

.file{
  .field-photo-credit{
    position: relative;
    margin-top: -35px;
    background-image: linear-gradient(to bottom right, rgba(0,0,0,0), rgba(0,0,0,0.7));
    padding: 3px 5px;
    color: #ccc;
    font-size: 0.8em;
    width: 100%;
    text-align: right;
    a{
      color: #ccc;
    }
  }
  .field-item::before{
    content:'source:';
    margin-right: 5px;
  }
}

.page-taxonomy .file .field-photo-credit{
  margin: -26px 0 0;
}

/* ANCHOR 嵌入影片畫面比例 */

.media-youtube-video iframe,
.oembed-provider-youtube iframe{
  aspect-ratio: 16 / 9;
  max-width: 100%;
  height: unset;
}

.cke_editable{ 
  .file-caption-left{
  float: left;
  clear: left;
  margin-right: 20px;
  }
  .file-caption-right{
  float: right;
  clear:right;
  margin-left: 20px;
  }
  .cke_widget_inline {
    display: block;
  }
}

.page-node{ 
  .media img{
  max-width: 100% !important;
  width: 100% !important;
  height: auto !important;
  }
  .media-caption_right{
    float: right;
    clear:right;
    margin-left: 20px;
  }
  .media-caption_left{
    float: left;
    clear: left;
    margin-right: 20px;
  }
  .media-wysiwyg-align-right,
  .media-wysiwyg-align-left,
  .media-caption_left,
  .media-caption_right{
    .media-youtube-player{
    height: auto !important;
    width: 100% !important;
    }
  }
  .field-image{
    .field-photo-credit .field-item{
      display: inline-block;
    }
    .field-item .file{
      text-align: center;
    }
  }
  .file{
    .field-caption{
    font-size: 0.8em;
    color: #244046;
    padding: 0 10px 5px;
    }
  }
}

.media iframe{
  max-width: 100%;
}

@media screen and (max-device-width: 768px){

  div.embeddedContent[data-align="right"],
  div.embeddedContent[data-align="left"],
	.media-element-container.media-wysiwyg-align-right,
	.media-element-container.media-wysiwyg-align-left,
	.media-element-container.media-caption_right,
	.media-element-container.media-caption_left{
	width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
	}

  div.embeddedContent[data-align="right"],
  div.embeddedContent[data-align="left"],
	.media-element-container.media-wysiwyg-align-left,
	.cke_editable .file-caption-left,
	.media-caption_left{
	float: none;
	margin-right: 0px;
	}

  div.embeddedContent[data-align="right"],
  div.embeddedContent[data-align="left"],
	.media-element-container.media-wysiwyg-align-right,
	.cke_editable .file-caption-right,
	.media-caption_right{
	float: none;
	margin-left: 0px;
  }
}
 
/* ANCHOR 2020 募資訂閱制 support */



/* ANCHOR  title */

.title,
.title-text,
.card-title,
.card-subtitle,
.card-header,
.card-footer {
  letter-spacing: 2px;
}

i.fa::before{
  margin-right: 5px;
}

/* ANCHOR vgr-card */

.vgr-cards {
  .card {
    display: flex;
    flex-flow: wrap;
    flex: 100%;
    margin-bottom: 40px;
    
    @media (max-width: 576px) {
      display: block;
    }
    
  }

  .card-img-body {
    flex: 1;
    overflow: hidden;
    position: relative;
    
    @media (max-width: 576px) {
      width: 100%;
      height: 200px;
          margin-bottom: 20px;
    }
    
  }
  
  .card-img {
    width: 100%;
    height: auto;
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
    
    @media (max-width: 1140px) {
          margin: 0;
    transform: none;
    width: 100%;
    height: auto;
    }
    
  }

  .card-body {
    flex: 2;
    padding: 0 0 0 1.25rem;
    
    @media (max-width: 576px) {
      padding: 0;
    }
  }
}

/* ANCHOR bef-select-as-links */

.bef-select-as-links{
  .form-item{
    display: inline-block;
    a{
      background: #46686F;
      padding: 5px 10px;
      color: #fff;
      border-radius: 10px;
      margin: 0 10px 10px 0;
      &:hover, &.active{
        background: #D7B02A;
        color: #000;
        text-decoration: none;
      }
    }
  }
}

/* ANCHOR views-exposed-form */

.views-exposed-form{
  .views-exposed-widgets{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
    .views-exposed-widget{
      display: flex;
      align-items: center;
      margin-right: 10px;
      > label{
        margin: 0 10px 0 0;
      }
      .form-item{
        margin-bottom: 0;
      }
      .bef-select-all-none{
        display: flex;
        .bef-toggle{
          margin-right: 10px;
        }
      }
      .bef-checkboxes{
        display: flex;
        flex-wrap: wrap;
        .form-item{
          margin-right: 10px;
        }
      }
    }
  }
  .form-control{
    max-width: 100% !important;
  }
}

#media-browser-tabset{
  #branding{
   h1 {
    height: unset;
    }
  }
}

.ui-widget{
  font-family: unset;
  input, select, textarea, button {
    font-family: unset;
    font-size: 1rem;
  }
}

select.form-control:not([size]):not([multiple]) {
  height: unset;
}

.form-group, .form-actions, .form-submit{
  margin-bottom: 1rem;
}

/* ANCHOR footnotes */

a{
  &.footnote-label,
  &.see-footnote{
    border-bottom: 0px solid #075666;
  }
  &.see-footnote{
    top: -0.3em;
    color: rgba(120, 0, 0, 1);
    &::after{
      content: "\f0ab";
      padding-left: 2px;
    }
  }
  &.footnote-label{
    color: rgba(0, 0, 0, 0.8);
    &::after{
      content: "\f0aa";
      padding-left: 2px;
    }
  }
  &.link::before{
    content: "\f0a9";
    padding-right: 5px;
  }
}

.footnotes {
  border-top: 1px solid #000000;
  clear: both;
  margin-bottom: 0;
  margin-top: 4em;
  padding: 2em;
  font-size: 1em;
}

:target {
  transition: background-color 1s;
}

.see-footnote:target{
  border: none;
  padding: 3px;
  background-color: rgba(255, 0, 0, 0.1);
  scroll-margin-top: 150px;
}

.footnotes .footnote:target {
  padding: 15px 5px;
  background-color: rgba(255, 0, 0, 0.1);
  scroll-margin-top: 150px;
}

/* ANCHOR 青年論文競賽 */

.da{
  --bg-color:#000103;
  --menu-color-bg: #ffcc00;
  --menu-color-text: #000103;
  --menu-color-border: rgba(0, 1, 3, 0.5);
  --link-color: rgb(159, 117, 68);
  --link-color-border: rgb(205, 205, 205);
  --link-color-border-hover: rgb(159, 117, 68);
  --title-color: #000103;

  background: var(--bg-color);
  .slicknav_menu,
  .sticky-header-wrapper{
    display: none;
  }
  .region-content{
    padding-bottom: 200px;
  }
  .region-footer{
    margin-top: 0;
  }
  .full-width-container{
    height: 165px;
    background: transparent url(../image/da/2024/da_2024_banner.png) no-repeat center top;
    background-size: contain;
  }
  .fwc{
    .main-content-container{
      .text{
        background-color: rgba(255,255,255,0.85);
        .pane-content{
          padding: 1px 5% 40px;
          text-align: justify;
          max-width: 1000px;
          margin: auto;
          h2, h3{
            text-align: center;
            color: var(--title-color);
            font-size: 1.5rem;
            font-weight: bold;
            margin: 2rem 0;
            letter-spacing: 2px;
            &.title{
              color: #fff;
              font-size: 1rem;
            }
          }
          h3{
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .pane-superfish-4{
    &.panel-pane{
      margin-bottom: 0;
    }
    .pane-content{
      .sf-accordion-toggle{
        background-color: var(--menu-color-bg);
        text-align: center;
        a{
          color: var(--menu-color-text);
        }
      }
      #superfish-4-accordion{
        text-align: center;
        li{
          border-bottom: 1px solid var(--menu-color-border);
          a{
            background-color: color-mix(in srgb, var(--menu-color-bg), white 70%);
            color: var(--menu-color-text);
            transition: ease 0.5s;
            &:hover, &.active{
              background-color: var(--menu-color-bg);
            }
          }
        }
      }
    }
  }
  .webform-progressbar-page .webform-progressbar-page-label {
    font-size: 0.8em;
  }
  .messages{
    background-color: #fff;
    max-width: 1200px;
    margin: 30px auto;
  }
}

/* ANCHOR RWD Dissertation Award 青年論文競賽 */

@media (min-width: 544px){
  .da{
    .region-content{
      #block-system-main{
        &::before, &::after{
          height: 300px;
          width: 300px;
          left: 0;
          top: 54%;
        }
        &::after{
          left: unset;
          right: -80%;
          top: -160px;
        }
      }
    }
    .full-width-container{
      height: 260px;
      background: transparent url(../image/da/2024/da_2024_banner.png) no-repeat center top;
      background-size: contain;
    }
    .fwc{
      .main-content-container{
        .text{
          .pane-content{
            h2, h3{
              text-align: left;
            }
          }
        }
      }
    }
    .pane-superfish-4{
      &.panel-pane{
        margin-bottom: 0;
      }
      li{
        margin-right: 5px;
        background-color: transparent;
        a{
          letter-spacing: 1px;
          background-color: var(--menu-color-bg);
          color: var(--menu-color-text);
          padding: 15px;
          border-radius: 25px 5px 0 0;
          border-bottom: 5px solid var(--menu-color-border);
          &:hover, &.active{
            background-color: rgba(255,255,255,0.85);
            border-bottom: 5px solid transparent;
            &::after{
              content: '';
              display: block;
              width: 80%;
              border-bottom: 1px dashed #000;
              position: absolute;
              bottom: 0;
              left: 15px;
            }
          }
        }
        &.last{
          margin-right: 0;
        }
      }
    }
  }
}

@media (min-width: 769px){
  .da{
    .full-width-container{
      height: 400px;
    }
  }
}

@media (min-width: 1200px){
  .da{
    .pane-superfish-4{
      margin-top: 20px;
    }
  }
}

@media (min-width: 1441px){
  .da{
    .region-content{
      .block::before, .content::after{
        height: 400px;
        width: 400px;
        left: 10%;
        top: 54%;
      }
      .content::after{
        left: unset;
        right: -74%;
        top: -350px;
      }
    }
    .pane-superfish-4{
      li{
        a{
          padding: 30px 40px;
        }
      }
    }
  }
}

/* ANCHOR files */

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  filter:unset;
  opacity: unset;
}

.custom-file-control{
display: none;
}

.form-item{
  margin-bottom: 1rem;
  label{
    font-weight: 900;
  }
  .description{
    font-size: 0.8em;
    color: #575555; 
  }
}

/* ANCHOR Webform */

.form-required{
  color: red;
}

.webform-container-inline{
  div, div.form-item{
    display: inline-block;
  }
}

@media (min-width: 668px){
  .webform-client-form{
    .form-actions{
      margin-left: 160px;
    }
    .webform-container-inline {
      label{
        display: inline-block;
        margin-bottom: 0;
        width: 140px;
        margin-right: 10px;
        text-align: right;
        &.option{
          text-align: left;
        }
        &.custom-file{
          width: unset;
        }
      }
      input.form-text, .form-textarea-wrapper{
        display: inline-block;
        max-width: calc(100% - 160px);
      }
      .description{
        margin-left: 160px;
      }
      &.webform-component-checkboxes{
        label{
          vertical-align: top;
        }
        .form-checkboxes{
          display: inline-block;
          max-width: calc(100% - 160px);
          .form-item{
            display: block;
            label{
              width: unset;
            }
          }
        }
      }
    }
  }
}

/* ANCHOR AD */

.pane-block-10{
  padding: 15px;
  border: 1px dashed #fff;
  border-radius: 10px;
  background: linear-gradient(-135deg, rgba(223, 57, 252, 0.2) 10%, rgba(178, 235, 242, 0.3));
}

/* ANCHOR Podcast:contentjplayer */

.audio{
  &.tid_6220,
  &.tid_5775,
  &.tid_5185,
  &.tid_4296,
  &.tid_4544,
  &.tid_4410,
  &.tid_4798{
    .jp-audio{
      width: 100%;
      .jp-type-playlist{
        .jp-interface{
          border: 0px solid transparent;
          border-radius: 0.5rem;
          height: 180px;
          .jp-controls{
            a{
              &.jp-mute, &.jp-unmute{
                top: 140px;
                left: 20px;
              }
              &.jp-play{
                background: url(../image/jplayer/play.png) no-repeat center center;
              }
              &.jp-pause{
                background: url(../image/jplayer/pause.png) no-repeat center center;
              }
              &.jp-previous{
                background: url(../image/jplayer/previous.png) no-repeat center center;
              }
              &.jp-next{
                background: url(../image/jplayer/forward.png) no-repeat center center;
              }
              &.jp-stop{
                background: url(../image/jplayer/stop.png) no-repeat center center;
              }
              &.jp-mute{
                background: url(../image/jplayer/unmute.png) no-repeat center center;
              }
              &.jp-unmute{
                background: url(../image/jplayer/mute.png) no-repeat center center;
              }
            }
          }
          .jp-progress{
            top: 80px;
            left: 20px;
            width: 133px;
          }
          .jp-current-time{
            top: 104px;
            left: 20px;
            color: #fff;
          }
          .jp-duration{
            top: 104px;
            left: 33px;
            color: #fff;
          }
          .jp-volume-bar{
            top: 145px;
            left: 42px;
          }
        }
        .jp-playlist{
          display: none;
        }
      }
    }
  }
  &.tid_4296{
    .jp-interface{
      background: #B66A6B url(https://philomedium.com/sites/default/files/podcast_membership_s.png) no-repeat 180px center;
      background-size: contain;
    }
  }
  &.tid_4410{
    .jp-interface{
      background: #ffc52f url(https://philomedium.com/sites/default/files/podcast_ice_philosophy_s.png) no-repeat 180px center;
      background-size: contain;
    }
  }
  &.tid_4544{
    .jp-interface{
      background: #784090 url(https://philomedium.com/sites/default/files/flower_square_member.png) no-repeat 180px center;
      background-size: contain;
    }
  }
  &.tid_4798{
    .jp-interface{
      background: #286078 url(https://philomedium.com/sites/default/files/terminators_square_300_member.png) no-repeat 180px center;
      background-size: contain;
    }
  }
  &.tid_5185{
    .jp-interface{
      background: #0f1112 url(https://philomedium.com/sites/default/files/hfcp_square_300.png) no-repeat 180px center;
      background-size: contain;
    }
  }
  &.tid_5775{
    .jp-interface{
      background: #0f1112 url(https://philomedium.com/sites/default/files/HFNC_300.png) no-repeat 180px center;
      background-size: contain;
    }
  }
  &.tid_6220{
    .jp-interface{
      background: #0f1112 url(https://philomedium.com/sites/default/files/taxonomy/audio/202401%20%E8%B6%85%E9%A9%9A%E5%9A%87%E8%87%BA%E7%81%A3%E5%84%92%E5%AD%B8.png) no-repeat 180px center;
      background-size: contain;
    }
  }

}

@media (min-width: 668px){
  .audio{
    &.tid_4296{
      .jp-audio{
        width: 100%;
        .jp-type-playlist{
          .jp-interface{
            background: #B66A6B url(https://philomedium.com/sites/default/files/podcast_membership_w.png) no-repeat right center;
            background-size: contain;
          }
        }
      }
    }
    &.tid_4410{
      .jp-audio{
        width: 100%;
        .jp-type-playlist{
          .jp-interface{
            background: #ffcd63 url(https://philomedium.com/sites/default/files/ice_philosophy_player_2.png) no-repeat right center;
            background-size: contain;
          }
        }
      }
    }
    &.tid_4544{
      .jp-audio{
        width: 100%;
        .jp-type-playlist{
          .jp-interface{
            background: #784090 url(https://philomedium.com/sites/default/files/flower_fb_member.png) no-repeat right center;
            background-size: contain;
          }
        }
      }
    }
    &.tid_4798{
      .jp-audio{
        width: 100%;
        .jp-type-playlist{
          .jp-interface{
            background: #286078 url(https://philomedium.com/sites/default/files/terminators_fb_member_3.png) no-repeat right center;
            background-size: contain;
          }
        }
      }
    }
    &.tid_5185{
      .jp-audio{
        width: 100%;
        .jp-type-playlist{
          .jp-interface{
            background: #0f1112 url(https://philomedium.com/sites/default/files/image/node/s01e00_fb.png) no-repeat right center;
            background-size: contain;
          }
        }
      }
    }
    &.tid_5775{
      .jp-audio{
        width: 100%;
        .jp-type-playlist{
          .jp-interface{
            background: #0f1112 url(https://philomedium.com/sites/default/files/HFNC_fb.png) no-repeat right center;
            background-size: contain;
          }
        }
      }
    }
    &.tid_6220{
      .jp-audio{
        width: 100%;
        .jp-type-playlist{
          .jp-interface{
            background: #0f1112 url(https://philomedium.com/sites/default/files/202401%20%E8%B6%85%E9%A9%9A%E5%9A%87%E8%87%BA%E7%81%A3%E5%84%92%E5%AD%B8%20fb.png) no-repeat right center;
            background-size: contain;
          }
        }
      }
    }
  }
}

/* ANCHOR Podcast: embed */

.file-audio-oembed{
  iframe{
    min-height: 232px;
  }
}

/* ANCHOR subscriber */

.page-subscribers{
  .main.py-3{
    padding-bottom: 0 !important;
    .page-title{
      display: none;
    }
    .view-header{
      background:url(../image/subscriber/header.svg) no-repeat center top;
      background-size: cover;
      text-align: center;
      height: 278px;
      padding-top: 90px;
      margin-bottom: 30px;
      .title{
        font-size: 45px;
        font-weight: 900;    
        line-height: 1.2;    
        letter-spacing: 9px;    
        color: #0a5452;
      }
      .subtitle{
        font-size: 22.3px;    
        line-height: 1.2;    
        letter-spacing: 7px;    
        color: #5a918f;
      }
    }
    .view-content{
      .views-column{
        background-color: #F5F5F5;
      }
    }
    .view-footer{
      height:180px;
      background:url(../image/subscriber/footer.svg) no-repeat center top;
      background-size: cover;
    }
  }
  .region-footer{
    padding: 0;
    margin-top: 0;
  }
}

@media (min-width: 1200px){
  .page-subscribers{
    .main.py-3{
      .view-content{
        width: 100vw;
        margin-left: calc((100% - 100vw) /2);
        padding: 0 calc((100vw - 100%) / 2);
        background:url(../image/subscriber/back.svg) no-repeat center top;
        background-size: cover;
      }
    }
  }
}

/* ANCHOR 2021 募資訂閱制 support */

body.page-support{
  overflow-y: hidden;
  background: url(../image/support_2021/background_m2.png) repeat-y 0 0,linear-gradient(to bottom, #003546, #91a2a7);
  background-size: contain;
  .slicknav_menu, .sticky-header-wrapper{
    display: none;
  }
}

.support {
  .sticky-header-wrapper,
  .slicknav_menu{
    display: none;
  }

  .fwc{
    display: flex;
    position: relative;
    .container{
      padding: 40px 0;
      .title{
        font-size: 46px;
        border-bottom: 7px solid #ffbd0a;
        padding: 10px 35px;
        margin: 0 auto 70px;
        width: 300px;
      }
    }
  }

  #fwc-1{
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 400px;
      background-color: rgba(215,224,229,0.8);
      position: absolute;
      z-index: 0;
      top: 35%;
    }
    .container{
      color: #000;
      text-align: center;
      .subscriber, .mb-3{
        z-index: 3;
        position: relative;
      }
      small, p{
        letter-spacing: 2px;
      }
    }
  }

  #fwc-2{
    .description{
      max-width: 80%;
      margin: 40px auto 0;
    }
  }

  #fwc-3{
    .container{
      width: 100%;
      max-width: 100%;
    }
    .flexslider{
      background-color: transparent;
      border: 0;
      margin: 0;
      li{
        position: relative;
        .flex-caption{
          background-color: transparent;
          padding: 10px 0 10px 20px;
          color: #fff;
          position: absolute;
          left: 20px;
          top: calc((100% - 170px)/2);
          width: 220px;
          border-left: 10px dotted #ffb818;
        }
      }
      .flex-direction-nav{
        position: absolute;
        z-index: 10;
        top: calc( (100% - 40px)/2);
        a{
          &::before{
            font-size: 30px;
          }
        }
      }
    }
  }

  #fwc-4{
    .card{
      a{
        color: yellow;
        &:hover{
          color:#f49f28;
        }
      } 
    }     
  }
  #fwc-5{
    background-color: rgba(213,218,220,0.6);
  }

  #fwc-7, #fwc-8, #fwc-9, #fwc-10, #fwc-11{
    display: none;
  }

  .pane-title{
    .title-text{
      background-color: #F5F5F5;
      font-size: 2rem;
      font-weight: bold;
    }
  }

  #value{ // ANCHOR 募資訴求
    color: #fff;
    .outline{
      box-shadow: 0 0 5px #000;
    }
    .card{
      height: 100%;
      background-color: rgba(255,255,255,0.9);
      .card-content{
        p{
          text-align: center;
          margin: 1rem 0;
          color: #555;
          font-size: 27px;
          letter-spacing: 2px;
        }
      }
    }
    #desktop{
      display: none;
      .outline{
        margin-bottom: 20px;
      }
      .card{
        transition: 0.5s;
        box-shadow: 0px 0px 10px #000;
        &:hover{
        box-shadow: 0px 0px 15px #fff;
        font-weight: bold;
          .card-image{
            overflow: hidden;
            img{
              transform: scale(1.2);
            }
          }
        }
      }
    }
    #mobile{
      width: 80%;
      margin: 30px auto 0;
      .carousel-control-prev {
        left: -40px;  
      }
      .carousel-control-next {
        right: -40px;
      }
    }
  }

  #product{ // ANCHOR 回饋說明
    color: #fff;
    .outline{
      width: 100%;
    }
    .card{
      border: none;
      text-align: center;
      background-color: transparent;
      color: #fff;
      .card-image{
        background-color: white;
        background-position: center center;
        background-size: 60% 60%;
        background-repeat: no-repeat;
        border-radius: 50%;
        width: 200px;
        height: 200px;
        display: block;
        margin: 0 auto 20px;
      }
      .card-content{
        h4{
          letter-spacing: 5px;
          font-weight: bold;
        }
        p{
          margin: 1rem 0;
          text-shadow:  #000;
        }
      }
    }
    #desktop{
      .card{
        transition: 0.5s;
        &:hover{
          font-weight: bold
        }
      }
    }
  }

  #plan{/* ANCHOR 訂閱方案 */
    #first{
      .card-img-body{
        background: url(../image/support_2021/plan_a.png) no-repeat center top;
        background-size: cover;
      } 
    }
    #second{
      .card-img-body{
        background: url(../image/support_2021/plan_b.png) no-repeat center top;
        background-size: cover;
      }
    }

    #third{
      .card-img-body{
        background: url(../image/support_2021/plan_c.png) no-repeat center top;
        background-size: cover;
      }
    }

    #note{
      padding: 0 20px;
    }

    .card{
      color: #000;
      z-index: 11;
      border-radius: 5px;
      .card-img-body{
        height: 130px;
        .plan{
          margin-top: 39px;
          font-size: 3rem;
          color: #46686f;
          text-shadow: 1px 1px 3px #fff;
          text-align: center;
          font-weight: bolder;
          letter-spacing: 10px;
        }
      }
      .card-header{
        background-color: transparent;
        margin:0 20px;
        border-bottom:  dashed 1.5px #5d5d5d;
        h1{
          span{
            font-size: 0.4em;
          }
        }
        h4{
          span{
            font-size: 0.4em;
          }
        }
      }
      li{
        border: none;
        background: url(../image/support_2021/logo_1.png) no-repeat left 10px;
        padding-left: 25px;
        list-style: none;
      }
      .annual{
        h4{
          font-size: 1.2rem;
          display: inline-block;
          background-color: #43ada5;
          border-radius: 20px;
          padding: 5px 20px;
          color: #fff;
          margin: 20px 0;
        }
        li{
          background-image: url(../image/support_2021/logo_2.png);
        }
      }
      .card-footer{
        background-color: transparent;
        margin:0 20px;
        border-top:  dashed 1.5px #5d5d5d;
        padding: 0;
        .link{
          display: flex;
          justify-content:space-around;
          margin: 50px 0 20px;
          a.btn{
            font-size: 1.5em;
            padding: 3px 30px;
            border-radius: 30px;
            color: #fff;
            font-weight: bold;
            background-color: #43ada5;
            &:first-child{
              background-color: #a3a3a3;
            }
            &:hover{
              box-shadow: 0 0 5px #ffbd0a;
            }
          }
        }
      }
    }
  }
}

.funding_button{
  z-index: 10;
  position: fixed;
  bottom: 0;
  right: 0;
  #backer{
    color: #fff;
    margin-top: -20px;
    text-shadow: 0 0 3px #000;
    font-size: 1rem;
    font-weight: 900;
    text-align: center;
    #backer-count{
      font-size: 1em;
    }
  }
}

/* ANCHOR RWD for 2021 募資訂閱頁 */

@media (min-width: 544px) {
  .funding_button{
    #backer{
      font-size: 1.2rem;
    }
  }

  body.page-support{
    background: url(../image/support_2021/background.png) no-repeat 0 0,linear-gradient(to bottom, #003546, #91a2a7);
    background-size: cover;
  }

  .support{
    #fwc-1{
      &::before{
        top: 20%;
      }
      .slogan{
        font-size: 1.6rem !important;
      }
      .container{
        text-align: left;
      }
    }
    #fwc-2{
      .container{
        padding: 0;
        .flexslider{
          border: 0;
          .slides > li{
            position: relative;
          }
          .flex-caption{
            position: absolute;
            color: #fff;
            top: 30%;
            left: 20%;
            background-color: rgba(0,0,0,0.7);
            padding: 20px;
            border-radius: 30px;
            box-shadow: 0px 0px 20px #fff;
          }
        }
      }
    }
    
    #fwc-3{
      .flexslider{
        li{
          .flex-caption{
            left: calc((100% - 300px)/3);
            top: calc((100% - 170px)/3);
            width: 300px;
            h2{
              font-size: 42px;
            }
          }
        }
      }
    }
    
    #value{
      #mobile{
        display: none;
      }
      #desktop{
        display: block;
      }
    }
  }
}

@media (min-width: 768px) {
  .support{
    #fwc-1{
      &::before{
        height: 200px;
      }
    }
  }
  .funding_button{
    bottom:20px;
    #backer{
      font-size: 1.2rem;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px){
  .support{
    #plan{
      .card{
        .card-img-body{
          .plan{
            font-size: 2rem;
            margin-top: 44px;
          }
        }
        .card-header{
          h1{
            font-size: 2rem;
            span{
              font-size: 0.4em;
            }
          }
          h4{
            span{
              font-size: 0.4em;
            }
          }
        }
        .card-footer{
          .link{
            a.btn{
              padding: 3px 15px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .support{
    #fwc-1{
      &::before{
        height: 300px;
      }
    }
  }
}

@media (min-width: 1440px) {
  .support{
    #fwc-1{
      &::before{
        height: 400px;
      }
    }
  }
  .funding_button{
    right: unset;
    left: calc(((100% - 1200px) / 2) - 9%);
    top: 20%;
    bottom: unset;
    width: 10%;
    #backer{
      font-size: 1.2rem;
    }
  }
}

/* ANCHOR 閱讀進度條 */

.bar-long{
  position: unset !important;
  height: 1.5px;
}

/* ANCHOR Page */

.node-type-page{
  .region-content{
    .content{
      .container{
        max-width:800px;
      }
    }
  }
}

/* ANCHOR 關於我們 */

#block-views-link-block-1,
#block-views-milestone-block-1{
  max-width: 800px;
  margin: 0 auto;
  > h2{
    text-align: center;
    margin: 60px 0;
    font-weight: bold;
  }
}


.tl-text{
  h2.tl-headline-title, h2.tl-headline{
    margin: 20px 0;
    line-height: 1.5;
  }
}

.tl-slide{
  .tl-slide-content-container{
    .tl-slide-content{
      display: flex;
      align-items: center;
      h2{
        margin: 30px 0;
        font-weight: bold;
        line-height: 1.5;
      }
    }
  }
}

.tl-media{
  .tl-media-iframe{
    iframe{
      width: unset !important;
      height: unset !important;
    }
  }
}

/* ANCHOR 轉換訂閱方案 */

.migrate .row div{
  padding: 5%;
}

.migrate .now{
  position: relative;
}

.migrate .now::before{
  content: '目前方案';
  display: block;
  text-align: center;
  font-size: 5rem;
  font-weight: bold;
  padding-top: 30%;
  background-color: rgba(255, 255, 255, 0.8);
  width: 90%;
  height: 90%;
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: 2;
}

.migrate .migration img{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.migrate .migration a:hover img{
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.8);
}

.migrate .note{
  background-color: rgba(215,224,229,0.5);
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
}

/* ANCHOR Rate */

.rate-widget{
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 10px;
  .rate-info{
    margin-left: 5px;
  }
}

/* ANCHOR 最佳哲學書票選 */

.page-eform-submit-2019-best-book{
  .container-grid{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    .row{
      height:100%;
    }
  }
  .name-best-book-vote{
    h3{
      margin-block: 2em;
    }
    ul.grid{
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
}

.best-philobook .left{
  position: relative;
  .views-field-counter{
    position: absolute;
    top:-20px;
    left: calc(((100% - 200px) / 2) - 5%);
    z-index: 1;
    width: 60px;
    height: 60px;
    border: 2px solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #d7b02a 50%, darken(#d7b02a, 5%) 50%);
    font-size: 1.5em;
    font-weight: bold;
    color: #fff;
    text-shadow: 1px 1px 5px #000;
  }
  .book-cover{
    img{
      border-radius: 3px;
      filter: drop-shadow(1px 1px 5px #000);
    }
    &:hover{
      img{
        transform: rotate(-5deg);
        filter: drop-shadow(-1px -1px 5px #000);
      }
    }
  }
}

@media (min-width:768px){
  .page-eform-submit-2019-best-book{
    .container-grid{
      grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    }
    .name-best-book-vote{
      ul.grid{
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        .card:hover{
          box-shadow: 0 0 10px #969696;
        }
      }
    }
  }
}

/* ANCHOR 書籍 */

.views-field-field-difficulty,
.views-field-field-evaluation{
  display: flex;
  margin-bottom: 10px;
  .views-label{
    margin-right: 1em;
  }
  .field-content{
    padding-top: 10px;
  }
}

.shop.views-fieldset{
  .linkicon{
    list-style: none;
    margin: unset;
    padding: unset;
    a{
      display: flex;
      align-items: center;
      .icon{
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url(../image/icon_philomedium.svg) no-repeat center center / contain;
        margin-right: 5px;
        &.icon-books{
          background-image: url(../image/icon_books.svg);
        }
        &.icon-taaze{
          background-image: url(../image/icon_taaze.svg);
        }
        &.icon-readmore{
          background-image: url(../image/icon_readmoo.svg);
        }
      }
    }
  }
}

.breadcrumb{
  a, .delimiter{
    color: gray;
  }
}

/* ANCHOR 哲學星座 */

.constellation{
  .view-content{
    display: flex;
    flex-direction: column;
  }
}

@media (min-width:768px){
  .constellation{
    .view-content{
      flex-direction: row;
    }
  }
}

/* ANCHOR 搜尋框 */

#edit-search-block-form--2{
  height:unset;
  background: unset !important;
}

/* ANCHOR 精選文章 */

.selective{
  position: relative;
  p:last-child::after{
    content: '......';
  }
  .membership{
    text-align: center;
    padding: 120px 10% 10%;
    box-shadow: 0 0 20px rgba(0,0,0,.16);
    border-radius: 10px;
    position: relative;
    z-index: 2;
    top: -80px;
    background: linear-gradient(to bottom, rgba(255,255,255,.5) 0%, rgba(255,255,255,.9) 20%, #fff 100%);
  }
}


/* ANCHOR 合作夥伴 */

.name-partner .views-field-field-image .field-content{
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  padding: 20px;
  margin: 0 10px;
}

/* ANCHOR The End */